import React, { useState } from 'react';
import { Alert, Button, Form, Spinner } from 'react-bootstrap';
import NewsletterService from './NewsletterService'

class SignupForm extends React.Component {
  constructor() {
    super();
    this.originalEmailAddressErrorMessage = "Please enter a valid email address."
    this.state = {
      validated: false,
      emailAddress: '',
      emailAddressIsInvalid: false,
      emailAddressErrorMessage: this.originalEmailAddressErrorMessage,
      customerType: '',
      customerTypeIsInvalid: false,
      formSubmittedSuccessfully: false,
      formSubmissionPending: false
    }

    this.newsletterService = new NewsletterService()
  }

  handleSubmit = async (event) => {
    const form = event.currentTarget;
    this.setState({
      formSubmissionPending: true,
      emailAddressIsInvalid: false,
      emailAddressErrorMessage: this.originalEmailAddressErrorMessage,
      customerTypeIsInvalid: false,
    })

    event.preventDefault();
    event.stopPropagation();


    if (form.checkValidity() === true) {
      this.setState({validated: true})
      try {
        await this.newsletterService.createSubscriber(
          this.state.emailAddress,
          {
            "SOURCE": "website",
            "C_TYPE": this.state.customerType
          }
        )
        this.setState({formSubmittedSuccessfully: true})
      } catch (e) {
        console.log(e)
        switch (e.response.status) {
          case 400:
            if (e.response.data.message == "InvalidRequest" &&
              e.response.data.data.errors.email_address !== undefined) {
              this.setState({
                formSubmissionPending: false,
                validated: false,
                emailAddressIsInvalid: true,
                emailAddressErrorMessage: e.response.data.data.errors.email_address.pop()
              })
            }
            break;
          default:
            console.log("Something else. Not handled yet")
            break;
        }

      }
    } else {
      console.log(form)
      this.setState({
        validated: false,
        emailAddressIsInvalid: this.state.emailAddress == '' ? true : this.state.emailAddressIsInvalid,
        formSubmissionPending: false,
        customerTypeIsInvalid: this.state.customerType == '' ? true : false,
      })
    }
  };
  render() {
    return (
      <div className="signup-form">
        {this.state.formSubmittedSuccessfully === true ?
          (<Alert variant="success">
              <Alert.Heading>Whoop!</Alert.Heading>
              <p>Thanks for entering your email address, you're now signed up to our newsletter. We will be in touch shortly.</p>
          </Alert>) :
          (<Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit} className="visible">
              <Form.Group className="mb-3">
                  <Form.Control
                    type="email"
                    placeholder="Enter your email"
                    name="email"
                    id="email-input"
                    required={true}
                    value={this.state.emailAddress}
                    onInput={e => this.setState({emailAddress: e.target.value, emailAddressIsInvalid: !e.target.checkValidity()})}
                    isInvalid={this.state.emailAddressIsInvalid}
                  >
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {this.state.emailAddressErrorMessage}
                  </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <p className="mb-0">I am a:</p>
                <Form.Check
                  inline
                  type="radio"
                  label="Food retailer"
                  id="food_retailer"
                  value="food_retailer"
                  name="customer_type"
                  onInput={e => this.setState({customerType: e.target.value, customerTypeIsInvalid: false})}
                  required
                  isInvalid={this.state.customerTypeIsInvalid}
                />
                <Form.Check
                  inline
                  type="radio"
                  label="Food finder"
                  id="food_finder"
                  value="food_finder"
                  name="customer_type"
                  onInput={e => this.setState({customerType: e.target.value, customerTypeIsInvalid: false})}
                  required
                  isInvalid={this.state.customerTypeIsInvalid}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                  <div className="d-grid gap-2">
                      <Button type="submit" disabled={this.state.formSubmissionPending ? "disabled" : null }>
                        {this.state.formSubmissionPending ? (
                          <Spinner
                            as="span"
                            variant="light"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            animation="border"/>
                        ) : null}
                          &nbsp;Sign up
                      </Button>
                  </div>
              </Form.Group>
          </Form>
        )}
      </div>
    );
  }
}

export default SignupForm;
