import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import SignupForm from "../../SignupForm"
import Logo from "./streats-logo.jpg"

function LandingPage() {
  return (
    <Container fluid className="landing-page min-vh-100 d-flex ">
        <Helmet>
          <title>Steats Food | Home</title>
          <meta name="description" content="Streats food newsletter signup"/>
          <meta name="keywords" content="Streats, food, Street, coming, soon, news, newsletter, food, vendor, festivals"/>
        </Helmet>
        <Row className="flex-grow-1">
            <Col xs={{span: 10, offset: 1}} md={{span: 5, offset: 7}} className="landing-page__left">
              <Row>
                  <Col xs={{span: 10, offset:1}}>
                    <div className="text-center mb-5">
                      <img src={Logo}/>
                    </div>
                    <h1 className="mb-5">Eat Street!</h1>
                    <h3 className="mb-5">Discover the best street food vendors and festivals by you.</h3>
                    <div className="mb-5">
                      <p>Our new website is coming soon, signup with the form below to be the first to know.</p>
                    </div>
                    <SignupForm></SignupForm>
                  </Col>
                </Row>
            </Col>
        </Row>
    </Container>
  );
}

export default LandingPage;
