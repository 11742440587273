import axios from 'axios';

export default class NewsletterService {
    constructor() {
        this.apiHostname = "api.streatfood.org";
    }

    async createSubscriber(emailAddress, fields) {
        const response = await axios.post(
            `https://${this.apiHostname}/subscribers`,
            {
                "email_address": emailAddress,
                "fields": fields
            }
        );
        console.log(response);

        return true
    }
}