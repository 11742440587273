import logo from './logo.svg';
import './App.scss';
import LandingPage from './components/LandingPage/LandingPage'

function App() {
  return (
    <div className="streats d-flex">
      <LandingPage></LandingPage>
    </div>
  );
}

export default App;
